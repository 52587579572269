@import url("https://fonts.googleapis.com/css2?family=Syne:wght@400;700&family=Inter:wght@400;700&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  @apply bg-light-background text-light-text dark:bg-dark-background dark:text-dark-text;
  font-family: "Inter", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  scroll-behavior: smooth;
}

.font-syne {
  font-family: "Syne", sans-serif;
}
